import React from 'react';
import { graphql } from 'gatsby';
import { Box, Flex } from '../style/basicStyle';
import LayoutWithAuth from '../components/layoutWithAuth';
import SignInForm, { SignInGoogle, SignInFacebook, SignInTwitter } from '../components/SignIn';
/*

import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';

export const SignInPageOLD = () => (
  <>
    <h1>SignIn</h1>
    <SignInForm />
    <SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordForgetLink />
    <SignUpLink />
  </>
);
*/

// eslint-disable-next-line react/prop-types
const Named = ({ location }) => {
  // eslint-disable-next-line react/prop-types
  const redirect = location && location.state && location.state.redirect;
  return (
    <Box py={[5]}>
      <LayoutWithAuth>
        <SignInForm redirect={redirect} />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query signinContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "signin" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
